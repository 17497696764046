import { styled, TextareaAutosize, TextField, Typography } from '@mui/material';
import { IconAsteriskSimple } from '@tabler/icons-react';
import { colors } from 'assets/styles/MMSCANTheme';

export const StyledInputLabel = styled(Typography)(({ isdark }: any) => ({
	fontSize: '12px',
	fontWeight: '500',
	marginBottom: '8px',
	color: isdark ? colors.white.main : colors.black.shade2,
	position: 'relative',
	textTransform: 'capitalize'
})) as typeof Typography as any;

export const StyledTextInput = styled(TextField)(({ isdark, size }: any) => ({
	'& .MuiInputBase-root': {
		height: 'inherit',
		fontSize: '12px',
		color: isdark ? colors.white.main : colors.black.shade2,
		border: `1px solid ${isdark ? '#BFC1C333' : 'transparent'}`,
		background: isdark ? colors.grey.shade4 : '',
		padding: isdark ? '0' : ''
	},

	'& .MuiInputBase-input': {
		paddingTop: size === 'small' && '11.5px',
		paddingBottom: size === 'small' && '11.5px',
		lineHeight: '18px',
		color: isdark ? colors.white.main : colors.black.shade2,

		...(isdark && {
			'&:-webkit-autofill': {
				'-webkit-text-fill-color': colors.white.main,
				height: '7px',
				boxShadow: `0 0 0 50px ${colors.grey.shade4} inset`,
				caretColor: colors.white.main,
				animationName: 'none'
			}
		}),

		'&::placeholder': {
			textTransform: 'capitalize'
		}
	},

	'& .Mui-disabled': {
		'&::placeholder': {
			color: isdark ? colors.white.main : 'rgba(0, 0, 0, 0.26)',
			webkitTextFillColor: isdark ? colors.white.main : 'rgba(0, 0, 0, 0.26)'
		}
	}
})) as typeof TextField as any;

export const StyledTextareaAutosize = styled(TextareaAutosize)(() => ({
	width: '100%',
	fontSize: '12px',
	color: colors.black.shade2,
	border: `1px solid rgba(0, 0, 0, 0.23)`,
	background: 'transparent',
	padding: '10.5px 14px',
	lineHeight: '18px',
	borderRadius: '4px',
	resize: 'none',
	outline: 'none',
	fontFamily: 'MMC',
	'&::placeholder': {
		color: '#B6B7B7',
		letterSpacing: '0.2'
	}
})) as any;

export const AsteriskStyleIcon = styled(IconAsteriskSimple)(() => ({
	position: 'absolute',
	top: '2px',
	marginLeft: '3px',
	width: '8px',
	height: '8px',
	color: colors.primary.main
})) as typeof IconAsteriskSimple;
