import { lazy } from 'react';

const AccessDenied = lazy(() => import('shared/components/MMSCANAccessDenied/MMSCANAccessDenied'));
const ActionItemReport = lazy(() => import('features/visitManagement/components/ActionItemReport'));
const AgendaActionContainer = lazy(() => import('features/visitManagement/AgendaActionContainer'));
const BusinessPlan = lazy(() => import('features/businessPlan/BusinessPlan'));
const BusinessPlanDetails = lazy(() => import('features/businessPlan/component/BusinessPlanDetails'));
const BusinessPlanHistory = lazy(() => import('features/businessPlan/component/BusinessPlanHistory'));
const ChangePassword = lazy(() => import('features/profile/components/ChangePassword'));
const ChangeRequest = lazy(() => import('features/financialDocs/components/ChangeRequest'));
const ClosedData = lazy(() => import('features/financialDocs/components/ClosedData'));
const Dashboard = lazy(() => import('features/dashboard/Dashboard'));
const DealerFormScreen = lazy(() => import('features/dealerSessionManagement/components/DealerFormScreen'));
const DealerSessionManagement = lazy(() => import('features/dealerSessionManagement/DealerSessionManagement'));
const EmbedReportContainer = lazy(() => import('features/reports/EmbedReportContainer'));
const EmbedReport = lazy(() => import('features/reports/components/EmbedReport'));
const FinancialStatement = lazy(() => import('features/financialStatement/FinancialStatement'));
const MyProfile = lazy(() => import('features/profile/components/MyProfile'));
const SubmissionTable = lazy(() => import('features/financialDocs/components/SubmissionTable'));
const User = lazy(() => import('features/user/UserScreen'));
const UserFormScreen = lazy(() => import('features/user/components/UserFormScreen'));
const VisitFormScreen = lazy(() => import('features/visitManagement/components/VisitFormScreen'));
const VisitManagement = lazy(() => import('features/visitManagement/VisitManagement'));
const VisitSummaryReport = lazy(() => import('features/visitManagement/components/VisitSummaryReport'));

export {
	AccessDenied,
	ActionItemReport,
	AgendaActionContainer,
	BusinessPlan,
	BusinessPlanDetails,
	BusinessPlanHistory,
	ChangePassword,
	ChangeRequest,
	ClosedData,
	Dashboard,
	DealerFormScreen,
	DealerSessionManagement,
	EmbedReport,
	EmbedReportContainer,
	FinancialStatement,
	MyProfile,
	SubmissionTable,
	User,
	UserFormScreen,
	VisitFormScreen,
	VisitManagement,
	VisitSummaryReport
};
