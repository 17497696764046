import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Box } from '@mui/material';
import isNull from 'lodash/isNull';
import isUndefined from 'lodash/isUndefined';

import HttpService from 'shared/services/Http.service';
import { API_CONFIG } from 'shared/constants/api';
import { createAction } from 'shared/util/Utility';
import { IDealer } from 'shared/interface';
import { IState } from 'shared/interface/state';
import SideNav from 'shared/components/Sidenav/SideNav';
import AutoRefresh from './components/AutoRefresh';
import * as actionTypes from 'store/action-types';
import { IUserData } from 'features/auth/login/LoginScreen.type';
import { LayoutProps } from './Layout.types';
import { ChildrenBoxStyles } from './style/Layout.styles';

const Layout: FC<LayoutProps> = (props: LayoutProps) => {
	const { pathname } = useLocation();
	const dispatch = useDispatch();
	const authData = useSelector((state: IState) => state.auth);

	const [showAutoRefreshPopup, setShowAutoRefreshPopup] = useState(false);
	const [isSideNavOpen, setIsSideNavOpen] = useState(false);

	const getUserProfile = async () => {
		try {
			const result = await HttpService.get(API_CONFIG.userProfile);
			const { first_name, last_name, email, role_id, dealer, job_title, version } = result.data;
			const currentVersion = localStorage.getItem('version');

			if (currentVersion !== version && !isUndefined(currentVersion) && !isNull(currentVersion)) {
				setShowAutoRefreshPopup(true);
			}
			localStorage.setItem('version', version);

			const userProfileDetails = {
				first_name,
				last_name,
				email,
				job_title,
				role_id,
				dealer_id: dealer.map((dealer: IDealer) => dealer.dealer_id)
			};

			dispatch(createAction(actionTypes.AUTH_SUCCESS, { ...(authData as IUserData), ...userProfileDetails }));
		} catch (error) {
			console.error(error);
		}
	};

	useEffect(() => {
		pathname !== '/my-profile' && getUserProfile();
	}, [pathname]);

	return (
		<>
			{showAutoRefreshPopup && process.env['REACT_APP_AUTO_REFRESH'] === 'true' && <AutoRefresh />}
			<Box sx={{ display: 'flex' }}>
				<SideNav isSideNavOpen={isSideNavOpen} setIsSideNavOpen={setIsSideNavOpen} />
				<Box component='main' sx={ChildrenBoxStyles(isSideNavOpen)}>
					{props.children}
				</Box>
			</Box>
		</>
	);
};

export default Layout;
