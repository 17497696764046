import React from 'react';
import { toast, ToastContainer, Id, ToastOptions } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Notification: React.FC = () => (
	<ToastContainer position='bottom-right' autoClose={false} hideProgressBar closeOnClick draggable pauseOnHover />
);

let toastId: Id | null = null;

type NotificationType = 'success' | 'error' | 'progress';

export const notify = (message: string, type: NotificationType = 'success', successAutoClose = true) => {
	const formattedMessage = (
		<div dangerouslySetInnerHTML={{ __html: message.charAt(0).toUpperCase() + message.slice(1) }} />
	);
	const progressToastConfig: ToastOptions = {
		toastId: 'progress',
		autoClose: false,
		closeButton: false,
		isLoading: true
	};

	if (type === 'progress') {
		toastId
			? toast.update(toastId, { render: formattedMessage, isLoading: true })
			: (toastId = toast.info(formattedMessage, progressToastConfig));
	} else {
		if (toastId) {
			toast.update(toastId, {
				render: formattedMessage,
				type,
				autoClose: successAutoClose ? 2000 : false,
				isLoading: false
			});
			toastId = null;
		} else {
			toast[type](formattedMessage, { autoClose: 2000 });
		}
	}
};

export default Notification;
