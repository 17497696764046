import { Box, Paper, styled, SxProps, Typography } from '@mui/material';
import { colors } from './MMSCANTheme';

export const StyledAuthTitle = styled(Typography)({
	fontSize: '24px',
	fontWeight: '700',
	textAlign: 'center',
	marginTop: '20px',
	marginBottom: '20px',
	color: colors.primary.main,
	'@media (max-height: 700px)': {
		margin: '10px 0'
	}
}) as typeof Typography;

export const StyledAuthContainer = styled(Paper)({
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	width: '100%',
	maxWidth: '400px',
	padding: '30px',
	borderRadius: '6px',
	'@media (max-height: 650px)': {
		padding: '20px'
	}
}) as typeof Paper;

export const CommonLinkSxProps = {
	fontSize: '12px',
	marginTop: '16px',
	alignSelf: 'end',
	display: 'flex',
	alignItems: 'center',
	gap: '5px',
	cursor: 'pointer',
	'&:hover': {
		textDecoration: 'underline'
	}
} as SxProps;

export const mainBox = {
	margin: '10px',
	boxShadow: `0px 5px 7px -1px ${colors.boxShadowColor}`,
	borderBottom: `1px solid ${colors.borderColor}`,
	backgroundColor: colors.white.main,
	borderRadius: '6px',
	paddingBottom: '8px',
	maxHeight: 'calc(100vh - 100px)',
	overflow: 'hidden'
} as SxProps;

export const tableTitleBox = {
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'space-between',
	marginBottom: '10px'
} as SxProps;

export const btnGroupBox = {
	display: 'flex',
	alignItems: 'center',
	gap: '10px'
} as SxProps;

export const tableTitle = {
	fontSize: '15px',
	fontWeight: 500,
	color: colors.secondary.shade7
} as SxProps;

export const actionBox = {
	display: 'flex',
	alignItems: 'center'
} as SxProps;

export const lightPrimaryBtn = {
	backgroundColor: colors.primary.shade4,
	color: colors.primary.main,
	':hover': {
		backgroundColor: colors.primary.shade4,
		color: colors.primary.main
	}
} as SxProps;

export const formBox = {
	margin: '10px',
	boxShadow: `0px 5px 7px -1px ${colors.boxShadowColor}`,
	borderBottom: `1px solid ${colors.borderColor}`,
	backgroundColor: colors.white.main,
	borderRadius: '6px',
	padding: '20px',
	width: '100%',
	height: '100%',
	alignItems: 'center',
	display: 'flex',
	flexDirection: 'column',
	maxWidth: '730px'
} as SxProps;

export const filteredChipRoot = {
	gap: '7px',
	display: 'flex',
	alignItems: 'center',
	padding: '5px 0'
};

export const filteredChipStyle = {
	borderRadius: '4px',
	fontWeight: 500,
	height: '27px',
	'.MuiChip-deleteIcon': { fontSize: '18px' },
	'.MuiChip-label': { fontSize: '12px' }
};

export const iconButton = {
	' & .MuiSvgIcon-root:hover': {
		fill: colors.primary.main
	}
};

export const boxWithColumns = { display: 'flex', gap: '20px' };

export const excelUploadErrorBox = {
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'start',
	justifyContent: 'center',
	marginBottom: '20px',
	gap: '5px'
} as SxProps;

export const errorTableTitle = {
	fontSize: '15px',
	marginLeft: '10px',
	color: colors.primary.main
} as SxProps;

export const FormContainer = styled(Box)({
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center'
}) as any;

export const dialogCloseButton = {
	position: 'absolute',
	top: '10px',
	right: '10px',
	borderRadius: '4px',
	border: `1px solid ${colors.primary.main}`,
	width: '20px',
	height: '20px',
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	cursor: 'pointer'
};

export const dialogCloseIcon = { width: '15px', height: '15px', color: colors.primary.main };

export const FilterButton = (isDark = false) => ({
	height: '100%',
	maxHeight: '32.50px',
	width: 'fit-content',
	fontSize: '14px',
	'&.Mui-disabled': {
		color: isDark ? colors.white.main : 'rgba(0, 0, 0, 0.26)',
		backgroundColor: isDark ? colors.primary.main : 'rgba(0, 0, 0, 0.12)',
		opacity: isDark ? 0.5 : 1,
		boxShadow: 'none'
	}
});
