import { combineReducers } from 'redux';

import { IAction, IState } from 'shared/interface/state';

import login, { initialState } from 'features/auth/login/store/login.reducer';
import businessPlanReducer, { businessPlanInitialState } from 'features/businessPlan/store/businessPlan.reducer';
import dealerReducer, { dealerDataInitialState } from 'features/dealerSessionManagement/store/dealer.reducer';
import closedDataReducer, { closedDataInitialState } from 'features/financialDocs/store/closedData.reducer';
import financialReducer, { fsFilterInitialState } from 'features/financialStatement/store/financialStatement.reducer';
import userReducer, { userDataInitialState } from 'features/user/store/user.reducer';
import actionScreenReducer, { actionScreenInitialState } from 'features/visitManagement/store/actionScreen.reducer';
import agendaActionReducer, { agendaActionInitialState } from 'features/visitManagement/store/agendaAction.reducer';
import visitReducer, { visitDataInitialState } from 'features/visitManagement/store/visit.reducer';
import VisitSummaryReportReducer, {
	visitSummaryReportInitialState
} from 'features/visitManagement/store/visitSummaryReport.reducer';
import actionItemReportReducer, {
	actionItemReportInitialState
} from 'features/visitManagement/store/actionItemReport.reducer';
import notificationReducer, { notificationInitialState } from 'shared/components/Sidenav/store/notification.reducer';
import commonReducer, { commonInitialState } from './common.reducer';

const appReducer = combineReducers({
	auth: login,
	notification: notificationReducer,
	user: userReducer,
	dealer: dealerReducer,
	visit: visitReducer,
	visitSummaryReport: VisitSummaryReportReducer,
	fs: financialReducer,
	common: commonReducer,
	closedData: closedDataReducer,
	actionItemReport: actionItemReportReducer,
	agendaAction: agendaActionReducer,
	actionScreen: actionScreenReducer,
	businessPlan: businessPlanReducer
});

const rootReducer = (state: IState | undefined, action: IAction) => {
	return appReducer(state, action);
};

export default rootReducer;

export const initialStore: RootState = {
	auth: initialState,
	notification: notificationInitialState,
	user: userDataInitialState,
	dealer: dealerDataInitialState,
	visit: visitDataInitialState,
	common: commonInitialState,
	fs: fsFilterInitialState,
	closedData: closedDataInitialState,
	agendaAction: agendaActionInitialState,
	actionScreen: actionScreenInitialState,
	businessPlan: businessPlanInitialState,
	visitSummaryReport: visitSummaryReportInitialState,
	actionItemReport: actionItemReportInitialState
};
export type RootState = ReturnType<typeof appReducer> | undefined;
