import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';
import { ThemeProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

import ErrorHandler from 'hoc/withErrorHandler/ErrorHandler';
import i18n from 'shared/util/localization';
import ErrorBoundary from 'shared/components/errorBoundary/ErrorBoundary';
import App from 'App';
import store from 'store';
import { theme } from 'assets/styles/MMSCANTheme';
import 'assets/styles/app.scss';
import 'react-datasheet-grid/dist/style.css';

const Root: React.FC = () => {
	const language = localStorage.getItem('lang');

	return (
		<I18nextProvider i18n={i18n}>
			<ErrorBoundary>
				<Provider store={store}>
					<LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={language || 'en'}>
						<ThemeProvider theme={theme}>
							<ErrorHandler />
							<BrowserRouter>
								<App />
							</BrowserRouter>
						</ThemeProvider>
					</LocalizationProvider>
				</Provider>
			</ErrorBoundary>
		</I18nextProvider>
	);
};

export default Root;
