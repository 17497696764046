import { FC } from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import { LoadingButtonProps } from '@mui/lab';
import { CircularProgress } from '@mui/material';
import { colors } from 'assets/styles/MMSCANTheme';

interface IProps extends LoadingButtonProps {
	text?: string;
	isSmall?: boolean;
}

const MMSCANButton: FC<IProps> = (props) => {
	const { text = 'Default Text', isSmall, sx, loading, ...rest } = props;
	return (
		<LoadingButton
			variant='contained'
			size={isSmall ? 'small' : 'large'}
			sx={{ fontSize: isSmall ? '14px' : '16px', textTransform: 'none', minWidth: 'fit-content', ...sx }}
			{...rest}
		>
			{loading ? (
				<CircularProgress
					size='24px'
					sx={{
						color: colors.white.main
					}}
				/>
			) : (
				text
			)}
		</LoadingButton>
	);
};

export default MMSCANButton;
