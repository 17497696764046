/* tslint:disable */

const en = {
	userList: 'Users List',
	financialStatement: 'Financial Statement',
	filter: 'Filter',
	search: 'Search',
	addUser: 'Add User',
	userFilter: 'User Filter',
	status: 'Status',
	selectStatus: 'Select Status',
	apply: 'Apply',
	reset: 'Reset',
	dealerCode: 'Dealer Code',
	dealerName: 'Dealer Name',
	actions: 'Actions',
	active: 'Active',
	inactive: 'Inactive',
	areYouSureToInactiveActive: 'Are you sure you want to inactive/active username user?',
	view: 'View',
	edit: 'Edit',
	delete: 'Delete',
	usersProfile: "User's Profile",
	phoneNumber: 'Phone Number',
	enterPhoneNumber: 'Enter Phone Number',
	areYouSureToDeleteUser: 'Are you sure you want to delete username user?',
	dealerFilter: 'Dealer Filter',
	region: 'Region',
	selectRegion: 'Select Region',
	pma: 'PMA',
	phevRetailer: 'PHEV Retailer',
	territory: 'Territory',
	activated: 'Activated',
	dealerPrincipal: 'Dealer Principal',
	generalManager: 'General Manager',
	prov: 'Prov.',
	dms: 'DMS',
	city: 'City',
	address: 'Address',
	pc: 'PC',
	website: 'Website',
	legalEntityName: 'Legal Entity Name',
	dealerPrincipalEmail: 'Dealer Principal Email',
	generalManagerEmail: 'General Manager Email',
	language: 'Language',
	regionClassification: 'Region Classification',
	pvClassification: 'PV Classification',
	dealerCodeValidation: 'Dealer code must be exactly five digits long.',
	firstNameValidation: 'First Name cannot exceed 50 characters',
	jobTitleRequired: 'Job Title is required',
	validWebsiteUrl: 'Please enter a valid website URL.',
	faxValidation: 'Fax number can only contain digits, spaces, parentheses, dashes, and plus signs.',
	commentsValidation: 'Comments cannot exceed 300 characters',
	planNameMinLength: 'Plan Name must be at least 2 characters long',
	planNameMaxLength: 'Plan Name cannot exceed 100 characters',
	endDateValidation: 'End date and time should not be less than or equal to start date and time.',
	fileSizeValidation: 'File size exceeds the permitted limit. Please upload a file within 5 MB.',
	phoneNumberValidation:
		'Phone number must contain only digits, parentheses, dashes, and plus signs, and must be between 8 to 12 characters long.',
	passwordValidation:
		'Password must be 8 to 16 characters long, including at least one uppercase letter, one lowercase letter, one special character and one digit.',
	accountVerificationFailed:
		'Your Account verification has been failed. Click the below button to resend the verification email.',
	updateDealer: 'Update Dealer',
	dealersProfile: "Dealer's profile",
	noDealerFound: 'No dealer found',
	monthWiseFinancialStatement: 'Monthly Financial Statement',
	noMonthWiseFinancialStatementFound: 'No monthly financial statement found',
	lastUpdatedOn: 'Last Updated On',
	mitsubishiCanada: 'Mitsubishi Canada',
	page: 'page',
	assetsLiabilities: 'Assets & Liabilities',
	salesPL: 'Sales - P&L',
	aftersalesPL: 'Aftersales - P&L',
	miscellaneousIndicators: 'Miscellaneous Indicators',
	noFinancialStatementFound: 'No financial statement found',
	month: 'Month',
	totalOpExpense: 'Total Op. Expense',
	changeRequest: 'Change Request',
	submittedOn: 'Submitted On',
	monthYearToBeUpdated: 'Month-Year to Be Updated',
	approve: 'Approve',
	reject: 'Reject',
	submissions: 'Submissions',
	startDateToSubmitFS: 'Start Date To Submit FS',
	lockDateToSubmitFS: 'Lock Date To Submit FS',
	visitFilter: 'Meeting Filter',
	addAVisit: 'Add A Meeting',
	visitCode: 'Meeting Code',
	visitDate: 'Meeting Date',
	conductedBy: 'Conducted By',
	createdBy: 'Created By',
	department: 'Functional Area',
	subDepartment: 'Sub Functional Area',
	visitType: 'Meeting Mode',
	agendaItems: 'Review Checklist',
	actionItems: 'Action Plan',
	areYouSureRemoveAgendaItem: 'Are you sure you want to remove this review checklist?',
	online: 'Online',
	call: 'Call',
	onsite: 'Onsite',
	toDo: 'To Do',
	inProgress: 'In Progress',
	completed: 'Completed',
	high: 'High',
	medium: 'Medium',
	low: 'Low',
	visitConductedBy: 'Meeting Conducted By',
	startDateTime: 'Meeting Start Date & Time',
	selectDate: 'Select Date',
	selectTime: 'Select Time',
	endDateTime: 'Meeting End Date & Time',
	participants: 'Attendees',
	addParticipants: 'Add Attendees',
	searchParticipant: 'Search Attendee',
	noParticipants: 'There are no attendees.',
	visitStartEndDate: 'Meeting Start Date / End Date',
	noAgendaItemFound: 'No review checklist found',
	addNewAgenda: 'Add New Review',
	save: 'Save',
	comment: 'Comment',
	writeYourCommentHere: 'Write Your Comment Here',
	actionPlan: 'Action Plan',
	confirmAction: 'Confirm Action',
	taskId: 'Task ID',
	commentHistory: 'Comment History',
	viewAction: 'View Action',
	viewAgenda: 'View Review',
	noActionItemFound: 'No action plan found',
	pending: 'Pending',
	clearAll: 'Clear All',
	signOut: 'Sign Out',
	welcome: 'Welcome',
	close: 'Close',
	areYouSureTo: 'Are you sure you want to',
	user: 'user',
	dealer: 'Dealer',
	firstName: 'First Name',
	lastName: 'Last Name',
	email: 'Email',
	jobTitle: 'Job Title',
	verified: 'Verified',
	uploadImage: 'Upload Image',
	role: 'Role',
	select: 'Select',
	confirm: 'Confirm',
	cancel: 'Cancel',
	update: 'Update',
	add: 'Add',
	enter: 'Enter',
	noOptionFound: 'No option found',
	no: 'No',
	found: 'found',
	users: 'Users',
	dealers: 'Dealers',
	closeData: 'Close Data',
	reports: 'Reports',
	visitManagement: 'Meeting Console',
	businessPlan: 'Business Plan',
	notifications: 'Notifications',
	myProfile: 'My Profile',
	changePassword: 'Change Password',
	logout: 'Logout',
	enterYour: 'Enter Your',
	oldPassword: 'Old Password',
	newPassword: 'New Password',
	confirmPassword: 'Confirm Password',
	phone: 'Phone',
	fax: 'Fax',
	generalMangerGM: 'General Manager (GM)',
	copied: 'Copied',
	all: 'All',
	submitted: 'Submitted',
	reSubmitted: 'Re-Submitted',
	downloaded: 'Downloaded',
	visit: 'Meeting',
	markAllAsRead: 'Mark all as read',
	notificationsNotAvailable: 'Notifications not available.',
	showMore: 'Show More',
	yayYouHaveSeenItAll: 'Yay! You have seen it all',
	selectDealer: 'Select Dealer',
	downloadFS: 'Download FS',
	generatingFS: 'Generating Financial Statement for',
	pleaseHoldOn: 'Please hold on',
	year: 'Year',
	fsContainsNegativeVal: 'FS contains negative values',
	salesGrossProfit: 'Sales - Gross Profit',
	afterSalesGrossProfit: 'Aftersales - Gross Profit',
	yes: 'Yes',
	request: 'Request',
	lock: 'Lock FS',
	unlock: 'Unlock FS',
	agendaAndAction: 'Review Checklist & Action Plan',
	publishAndShare: 'Publish & Share',
	agendaItem: 'Review Checklist',
	actionItem: 'Action Plan',
	agenda: 'review',
	addAgenda: 'ADD REVIEW',
	selectedAgendaItems: 'Selected Review Checklist',
	removeMonoAgenda: 'REMOVE MONO REVIEW',
	title: 'Title',
	id: 'ID',
	assignTo: 'Assigned To',
	dueDate: 'Due Date',
	addActionItems: 'Add Action Plan',
	areYouSureYouWantToDelete: 'Are you sure you want to delete',
	saveAndNext: 'Save & Next',
	next: 'Next',
	internalComment: 'Internal Comment',
	writeYourInternalMsg: 'Write your internal comment here',
	dashboard: 'Dashboard',
	noReportFound: 'No report found',
	new: 'New',
	parts: 'Parts',
	used: 'Used',
	service: 'Service',
	bodyShop: 'Body Shop',
	balanceSheet: 'Balance Sheet',
	workingCapital: 'Working Capital',
	powerBIReport: 'PowerBI Report',
	bizDrivePoweredByAutoRuptiv: 'BizDrive. Powered by AutoRuptiv',
	createNewPlan: 'Create New Plan',
	category: 'Category',
	commentForRejection: 'Comment for Rejection',
	typeYourFeedbackOrReasonForRejection: 'Type your feedback or reason for rejection...',
	planID: 'Plan ID',
	planName: 'Plan Name',
	createdDate: 'Created Date',
	submission: 'Submission',
	downloadBusinessPlanPDF: 'Download Business Plan PDF',
	approved: 'Approved',
	rejected: 'Rejected',
	pendingApproval: 'Pending Approval',
	draft: 'Draft',
	range: 'Range',
	PV: 'PV',
	PVRegionDealer: 'PV/Region/Dealer',
	noCommentsFound: 'No comments found',
	totalExpenses: 'Total Expenses',
	totalOperations: 'Total Operations',
	KPIDescription: 'KPI Description',
	actual: 'Actual',
	target: 'Target',
	history: 'History',
	submitForApproval: 'Submit for Approval',
	businessPlanDetails: 'business plan details',
	details: 'Details',
	create: 'Create',
	generate: 'Generate',
	previous: 'Previous',
	selectBaseline: 'Select Baseline',
	chooseOneOption: 'Choose One Option',
	dealerOrRegionOrPV: 'Dealer or Region or PV',
	businessPlanForYear: 'Business Plan for year',
	isAlreadyCreatedDoYouWantToViewIt: 'is already created. Do you want to view it',
	option: 'option',
	facilitator: 'Facilitator',
	by: 'By',
	on: 'on',
	noHistoryFound: 'No history found',
	at: 'at',
	annualTarget: 'Annual target',
	imageISUploading: 'Image is uploading',
	assign: 'Assign',
	agendaItemValidation: 'Review name cannot exceed 200 characters',
	deleteActionItem: 'Are you sure you want to delete this action plan?',
	assignee: 'Assignee',
	here: 'Here',
	tagUser: 'Tag User',
	replies: 'Replies',
	reply: 'Reply',
	closeThread: 'Close Thread',
	hide: 'Hide',
	priority: 'Priority',
	uploadedFiles: 'Uploaded Files',
	name: 'Name',
	uploadActionDocs: 'Upload Action Docs',
	noFileChosen: 'No File chosen',
	deleteAgendaItem: 'Are you sure you want to delete this review?',
	send: 'Send',
	uploadFiles: 'Upload Files',
	roleInformation: 'Role Information',
	fieldRequired: 'This field is required',
	invalidEmailAddress: 'Invalid email address',
	isRequired: 'is required',
	charValidation50: 'cannot exceed 50 characters',
	charValidation100: 'cannot exceed 100 characters',
	passwordNotMatched: 'Password do not match',
	password: 'Password',
	actionDocument: 'Action document',
	duplicateFileError: 'Duplicate files are not allowed.',
	maxFileUploadValidation5: 'You can only upload a maximum of 5 files at a time.',
	maxFileUploadValidation10: 'You can only upload a maximum of 10 files.',
	fileLimitValidation: 'File size exceeds the permitted limit. Please upload a file within 1 MB.',
	invalidImgTypeMsg:
		'Invalid file. Please upload a file with one of the following extensions: .jpeg, .jpg, .png, .webp',
	noBusinessPlanFound: 'No business plan found',
	areYouSureYouWantToLogout: 'Are you sure you want to Logout?',
	visitSummaryReport: 'Meeting Summary Report',
	signature: 'Signature',
	clear: 'Clear',
	trim: 'Trim',
	pendingSignature: 'Pending Signature',
	saveYourLatestChanges: 'You have unsaved changes.',
	doYouWantSaveChanges: 'Do you want to save your changes before leaving this page?',
	firstNameRequired: 'First Name is required',
	lastNameRequired: 'Last Name is required',
	selectRole: 'Select Role',
	from: 'From',
	to: 'To',
	atlantic: 'Atlantic',
	bc: 'BC',
	mitsubishi: 'Mitsubishi',
	ontario: 'Ontario',
	quebec: 'Quebec',
	western: 'Western',
	total: 'Total',
	discard: 'Discard',
	startDateValidation: 'Start date must be less than or equal to the end date.',
	actionItemReport: 'Action Plan Report',
	noDataAvailable: 'No Data Available',
	totalVisit: 'Total Meeting',
	totalActionItems: 'Total Action Plan',
	lookup: 'Account Lookup',
	searchAccIdOrKPI: 'Search by Account Id or KPI',
	helpNotAvailable: 'No KPIs found',
	dealerType: 'Type',
	prospect: 'Prospect',
	addNewDealerOrProspect: 'Add New Dealer / Prospect',
	sales: 'Sales',
	afterSales: 'After Sales',
	general: 'General',
	marketing: 'Marketing',
	networkDevelopment: 'Network Development',
	training: 'Training',
	others: 'Others',
	followUpDate: 'Follow Up Date',
	visitDetails: 'Meeting Details',
	copyVisit: 'Copy Meeting',
	copy: 'copy',
	this: 'this',
	uploadAgendaDocs: 'Upload Review Docs',
	january: 'Jan.',
	february: 'Feb.',
	march: 'Mar.',
	april: 'Apr.',
	may: 'May',
	june: 'Jun.',
	july: 'Jul.',
	august: 'Aug.',
	september: 'Sep.',
	october: 'Oct.',
	november: 'Nov.',
	december: 'Dec.',
	underConstruction: 'This page is currently under construction',
	more: 'more',
	selectReport: 'Select Report',
	selectReportType: 'Select Report Type',
	export: 'Export',
	visitStatus: 'Meeting Status',
	downloadMeetingPDF: 'Download Meeting Console PDF',
	statusConfirmationMsg1: 'Some action plans are still incomplete.',
	statusConfirmationMsg2: 'Are you sure you want to change the status of this meeting console?',
	dealerNotifications: 'Dealer Notifications',
	exportStarted: 'Export started...',
	exportTo: 'Export to',
	exportInProgress: 'in progress',
	exportInProgressMsg1: 'Your report',
	exportInProgressMsg2: 'is being exported to a',
	exportInProgressMsg3: ' file. This might take a few minutes.',
	exportCompleted: 'Export completed successfully!',
	exportFailedTryAgain: 'Export failed. Please try again.',
	exportFailed: 'Export failed',
	exportReport: 'Export Report',
	exportWith: 'Export With',
	selectExportWith: 'Select Export With',
	withFilter: 'With Filter',
	withoutFilter: 'Without Filter',
	exportingView: 'Exporting View',
	selectExportingView: 'Select Exporting View',
	allPages: 'All Pages'
};

export default en;
