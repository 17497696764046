import moment, { Moment } from 'moment';
import { t } from 'i18next';
import { dateFormate } from 'shared/util/Utility';
import { EMAIL_REGEX, PASSWORD_REGEX, WEBSITE_REGEX, DEALER_CODE_REGEX } from './constant';
import localizationConstants from 'shared/util/translation/constant';

export const mandateValidation = {
	required: {
		value: true,
		message: t(localizationConstants.fieldRequired)
	}
};

export const validation = {
	required: {
		...mandateValidation
	},
	email: {
		...mandateValidation,
		pattern: {
			value: EMAIL_REGEX,
			message: t(localizationConstants.invalidEmailAddress)
		}
	},
	password: {
		...mandateValidation,
		pattern: {
			value: PASSWORD_REGEX,
			message: t(localizationConstants.passwordValidation)
		}
	}
};

export const generateValidation = (fieldName: string) => {
	const mandateValidation = {
		required: {
			value: true,
			message: `${fieldName} ${t(localizationConstants.isRequired)}`
		}
	};

	return {
		required: { ...mandateValidation },
		dealer_code: {
			...mandateValidation,
			pattern: {
				value: DEALER_CODE_REGEX,
				message: t(localizationConstants.dealerCodeValidation)
			}
		},
		first_name: {
			required: {
				value: true,
				message: t(localizationConstants.firstNameRequired)
			},
			maxLength: {
				value: 50,
				message: t(localizationConstants.firstNameValidation)
			}
		},
		last_name: {
			required: {
				value: true,
				message: t(localizationConstants.lastNameRequired)
			},
			maxLength: {
				value: 50,
				message: `${fieldName} ${t(localizationConstants.charValidation50)}`
			}
		},
		dba: {
			...mandateValidation,
			maxLength: {
				value: 50,
				message: `${fieldName} ${t(localizationConstants.charValidation50)}`
			}
		},
		job_title: {
			...mandateValidation,
			message: t(localizationConstants.jobTitleRequired)
		},
		email: {
			...mandateValidation,
			pattern: {
				value: EMAIL_REGEX,
				message: t(localizationConstants.invalidEmailAddress)
			}
		},
		password: {
			...mandateValidation,
			pattern: {
				value: PASSWORD_REGEX,
				message: t(localizationConstants.passwordValidation)
			}
		},
		website: {
			...mandateValidation,
			pattern: {
				value: WEBSITE_REGEX,
				message: t(localizationConstants.validWebsiteUrl)
			}
		},
		invalidEmail: {
			pattern: {
				value: EMAIL_REGEX,
				message: t(localizationConstants.invalidEmailAddress)
			}
		},
		comments: {
			...mandateValidation,
			maxLength: {
				value: 300,
				message: `${fieldName} ${t(localizationConstants.charValidation50)}`
			}
		},
		plan_name: {
			...mandateValidation,
			minLength: {
				value: 2,
				message: t(localizationConstants.planNameMinLength)
			},
			maxLength: {
				value: 100,
				message: `${fieldName} ${t(localizationConstants.charValidation100)}`
			}
		}
	};
};

export const validateName =
	(fieldName: string, isRequired = true) =>
	(value: string): string | boolean => {
		if (!value?.trim()) {
			return isRequired ? `${fieldName} ${t(localizationConstants.isRequired)}` : fieldName;
		}
		return true; // No error
	};

export const validatePasswordConfirmation = (newPassword: string, confirmPassword: string) => {
	if (newPassword !== confirmPassword) {
		return t(localizationConstants.passwordNotMatched);
	}
	return true; // No error
};

export const validateEndDate = (
	endDateVal: Moment | string,
	endTimeVal: Moment | string,
	startDateVal: Moment | string,
	startTimeVal: Moment | string
) => {
	// Convert Moment objects to formatted strings
	const formattedStartDate = dateFormate(startDateVal);
	const formattedEndDate = dateFormate(endDateVal);

	const formattedStartTime = dateFormate(startTimeVal, 'HH:mm');
	const formattedEndTime = dateFormate(endTimeVal, 'HH:mm');

	// Combine start date and time
	const startDateTime = moment(`${formattedStartDate} ${formattedStartTime}`, 'YYYY-MM-DD HH:mm');
	// Combine end date and time
	const endDateTime = moment(`${formattedEndDate} ${formattedEndTime}`, 'YYYY-MM-DD HH:mm');

	// Validate that endDateTime is after startDateTime and valid
	if (endDateTime.isValid() && endDateTime.isAfter(startDateTime)) {
		return true; // No validation error
	} else {
		return t(localizationConstants.endDateValidation);
	}
};
