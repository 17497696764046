import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Box, CircularProgress, Divider, IconButton, InputAdornment, SwipeableDrawer, Typography } from '@mui/material';
import { Search as SearchIcon } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

// Functions/Constants/Enum
import { debounce, handleKeyDown } from 'shared/util/Utility';
import HttpService from 'shared/services/Http.service';
import MMSCANTooltip from 'shared/components/MMSCANToolTip/MMSCANToolTip';
import MMSCANTextField from 'shared/components/MMSCANTextField/MMSCANTextField';
import { API_CONFIG } from 'shared/constants/api';
import localizationConstants from 'shared/util/translation/constant';

// Styles
import * as Sx from '../style/Notification.styles';
import { colors } from 'assets/styles/MMSCANTheme';

type NotificationProps = {
	isOpen: boolean;
	handleNotificationToggle: () => void;
};

const HelpDrawer: FC<NotificationProps> = ({ isOpen, handleNotificationToggle }) => {
	const searchRef: any = useRef(null);
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const listRef = useRef<any>(null);

	const [scrollPosition, setScrollPosition] = useState(0);
	const [searchQuery, setSearchQuery] = useState('');
	const [fsHelpList, setFSHelpList] = useState<any>([]);
	const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>(false);
	const [paginationParams, setPaginationParams] = useState({
		currentPage: 1,
		total: 0
	});

	// Fetch notifications from the API
	const fetchFSHelpList = useCallback(
		async (search = '', isRequiredOldData = false, page = paginationParams.currentPage) => {
			try {
				setLoading(true);
				const params = {
					current_page: page,
					record_per_page: 20,
					search
				};

				const response = await HttpService.get(API_CONFIG.searchFSKPIs, params);
				const { account_master, pagination_data } = response.data;

				setFSHelpList((prevList: any) =>
					isRequiredOldData ? [...prevList, ...account_master] : account_master
				);
				setPaginationParams({ currentPage: pagination_data?.current_page, total: pagination_data?.total });
				setLoading(false);

				// Scroll to current position
				setScrollPosition(listRef?.current?.scrollTop || 0);
				if (listRef.current) {
					listRef.current.scrollTo({
						top: listRef.current && !isRequiredOldData ? 0 : listRef?.current?.scrollTop,
						behavior: 'smooth'
					});
				}
			} catch (error) {
				console.error(error);
				setLoading(false);
			}
		},
		[dispatch]
	);

	const handleSearch = debounce((value: string) => {
		setSearchQuery(value.trim());
	}, 400);

	const handleCloseNotification = () => {
		setIsDrawerOpen(false); // Close the drawer first
		setTimeout(() => handleNotificationToggle(), 300); // Then update parent state
	};

	useEffect(() => {
		if (listRef.current) {
			listRef.current.scrollTo({
				top: scrollPosition
			});
		}
	}, [scrollPosition]);

	useEffect(() => {
		fetchFSHelpList(searchQuery);
	}, [fetchFSHelpList, searchQuery]);

	useEffect(() => {
		setIsDrawerOpen(isOpen);
		return () => {
			fetchFSHelpList();
		};
	}, [isOpen]);

	return (
		<SwipeableDrawer
			anchor='right'
			open={isDrawerOpen}
			swipeAreaWidth={isDrawerOpen ? 500 : 0}
			PaperProps={{ sx: { width: '540px' } }}
			onClose={handleCloseNotification}
			onOpen={handleNotificationToggle}
		>
			<Box sx={Sx.notificationTitleBox}>
				<Typography sx={Sx.notificationTitle} data-testid='helpTitle'>
					{t(localizationConstants.lookup)}
				</Typography>
				<MMSCANTooltip title={t(localizationConstants.close)} arrow>
					<IconButton onClick={handleCloseNotification} sx={{ padding: '0' }}>
						<CloseIcon />
					</IconButton>
				</MMSCANTooltip>
			</Box>
			<Divider />
			<MMSCANTextField
				type={'text'}
				inputRef={searchRef}
				label={''}
				sx={{ height: '32px', padding: '10px', marginBottom: '15px' }}
				placeholder={t(localizationConstants.searchAccIdOrKPI)}
				defaultValue={searchQuery || ''}
				InputProps={{
					endAdornment: (
						<InputAdornment position='end'>
							<IconButton>
								<SearchIcon sx={{ color: colors.black.main }} />
							</IconButton>
						</InputAdornment>
					),
					sx: { paddingRight: 0 }
				}}
				onKeyDown={handleKeyDown}
				onChange={({ target: { value } }) => handleSearch(value)}
			/>
			<Box
				sx={{ padding: '0 10px', height: 'calc(100vh - 100px)', overflow: 'auto', marginTop: '10px' }}
				ref={listRef}
			>
				{!loading && fsHelpList?.length === 0 && (
					<Typography sx={Sx.notificationStyles.noDataText}>
						{t(localizationConstants.helpNotAvailable)}
					</Typography>
				)}
				{fsHelpList?.length > 0 &&
					fsHelpList.map((notification: any, index: number) => {
						const { english_name, french_name, account_id } = notification;
						return (
							<Box
								key={index}
								sx={{
									...Sx.listItemStyle,
									backgroundColor: colors.secondary.shade4
								}}
								data-testid='helpListItem'
							>
								<Typography sx={{ wordBreak: 'break-word', fontSize: '14px', lineHeight: '18px' }}>
									{account_id} : {localStorage.getItem('lang') === 'fr' ? french_name : english_name}
								</Typography>
							</Box>
						);
					})}
				{loading && (
					<Box textAlign={'center'} margin={'20px 0'}>
						<CircularProgress data-testid='loader' color='primary' />
					</Box>
				)}
				{!loading && (
					<Box
						sx={{
							width: '100%',
							padding: '5px',
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center'
						}}
					>
						{paginationParams.total > fsHelpList?.length && (
							<Typography
								sx={Sx.showMoreText}
								onClick={() => {
									fetchFSHelpList(searchQuery, true, paginationParams.currentPage + 1);
								}}
							>
								{t(localizationConstants.showMore)}
								<KeyboardArrowDownIcon sx={{ marginTop: '2px' }} />
							</Typography>
						)}
						{paginationParams.total <= fsHelpList?.length && fsHelpList?.length > 0 && (
							<Typography>{t(localizationConstants.yayYouHaveSeenItAll)}</Typography>
						)}
					</Box>
				)}
			</Box>
		</SwipeableDrawer>
	);
};

export default HelpDrawer;
