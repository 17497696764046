import { createTheme } from '@mui/material/styles';

export const colors = {
	primary: {
		light: '#f0494f',
		main: '#ED0000', // Flush Mahogany color
		dark: '#a51319',
		shade4: '#fae7ea', // We Peep color,
		shade5: '#efe0e3',
		shade6: '#cd5968', // for table border
		shade7: '#e1afb5', // watermark
		shade8: '#ca0808',
		shade9: '#ed00001f',
		shade10: '#d32f2f',
		shade11: '#ED1C24'
	},
	secondary: {
		light: '#7d8387',
		main: '#5D646A', // Shuttle Gray color
		dark: '#41464a',
		shade4: '#F3F3F4', // Athens Gray color
		shade5: '#7A7C7F',
		shade6: '#e7ebee80', // for table border
		shade7: '#8D8E92', // for table title
		shade8: '#e1e1e1',
		shade9: '#e7ebee50', // for number cell border,
		shade10: '#e3e5e6',
		shade11: '#bfc2c4'
	},
	grey: {
		light: '#E2E4E6', // empty cell color
		main: '#E2E4E6', // table header color
		dark: '#C0C2C4', // table total color
		shade2: '#676D70',
		shade3: '#edeef0',
		shade4: '#343434',
		shade5: '#9E9E9E'
	},
	white: {
		main: '#FFFFFF' // White color
	},
	black: {
		main: '#000000', // Black color
		shade2: '#212429', // Shark Black color,
		shade3: '#000000bf'
	},
	green: {
		shade1: '#00A389',
		shade2: '#B6D7A8', // profit bg color
		shade3: '#63b441',
		shade4: '#179B6C',
		shade5: '#179B6C1A'
	},
	blue: {
		shade1: '#2A69B2',
		shade2: '#0969DA',
		shade3: '#5da8ff'
	},
	orange: {
		main: '#ffA500'
	},
	red: {
		main: '#FF0000'
	},
	borderColor: '#E8EBEF',
	boxShadowColor: 'rgb(28 48 74 / 6%)' // Box Shadow
};

export const theme = createTheme({
	palette: {
		primary: {
			light: colors.primary.light,
			main: colors.primary.main,
			dark: colors.primary.dark
		},
		secondary: {
			light: colors.secondary.light,
			main: colors.secondary.main,
			dark: colors.secondary.dark
		},
		text: {
			primary: colors.black.main
		}
	},
	typography: {
		fontFamily: ['MMC', 'Poppins'].join(',')
	},
	components: {
		MuiTextField: {
			styleOverrides: {
				root: {
					'& .MuiInputBase-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
						borderColor: colors.secondary.main
					}
				}
			}
		},
		MuiSelect: {
			styleOverrides: {
				root: {
					'&.MuiInputBase-root': {
						'&.Mui-focused .MuiOutlinedInput-notchedOutline': {
							borderColor: colors.secondary.main
						}
					}
				}
			}
		},
		MuiAutocomplete: {
			styleOverrides: {
				root: {
					'& .MuiOutlinedInput-root.MuiInputBase-sizeSmall .MuiAutocomplete-input': {
						padding: '6.4px 4px 3.7px 8px'
					}
				}
			}
		}
	}
});
