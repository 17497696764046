import { useSelector } from 'react-redux';
import {
	GridView,
	InsertChartOutlined as InsertChartOutlinedIcon,
	PersonOutline,
	LockReset
} from '@mui/icons-material';
import localizationConstants from 'shared/util/translation/constant';
import { MODULE, ROUTES } from 'shared/constants/constant';
import { IState } from 'shared/interface/state';
import { BusinessPlanIcon, DealerSessionMgtIcon, FSIcon, VisitIcon } from 'assets/images/icon';

const MenuItems = () => {
	const role = useSelector((state: IState) => state.auth.role?.role_name);
	//For FS access according to roles
	const userAccessFS = {
		Admin: ROUTES.SUBMISSION,
		'Special Priveledges': ROUTES.SUBMISSION,
		'Dealership User': ROUTES.FINANCIAL_STATEMENT,
		OEM: ROUTES.SUBMISSION
	};

	// Adjust the URL for Financial Statement based on the role
	const financialStatementUrl = userAccessFS[role as keyof typeof userAccessFS];
	const menuItems = {
		[MODULE.DASHBOARD]: {
			label: localizationConstants.dashboard,
			url: ROUTES.DASHBOARD,
			icon: <GridView />,
			className: 'dashboard'
		},
		[MODULE.USER]: {
			label: localizationConstants.users,
			url: ROUTES.USER,
			icon: <PersonOutline />,
			className: 'users'
		},
		[MODULE.DEALER_SESSION_MANAGEMENT]: {
			label: localizationConstants.dealers,
			url: ROUTES.DEALER_SESSION_MANAGEMENT,
			icon: <DealerSessionMgtIcon />,
			className: 'dsm'
		},
		[MODULE.FINANCIAL_STATEMENT]: {
			label: localizationConstants.financialStatement,
			url: financialStatementUrl,
			icon: <FSIcon />,
			className: 'fs'
		},
		[MODULE.CLOSED_DATA]: {
			label: localizationConstants.closeData,
			url: ROUTES.CLOSED_DATA,
			icon: <LockReset />,
			className: 'lock-fs'
		},
		[MODULE.REPORTS]: {
			label: localizationConstants.reports,
			url: ROUTES.REPORTS,
			icon: <InsertChartOutlinedIcon />,
			className: 'reports'
		},
		[MODULE.VISIT_MANAGEMENT]: {
			label: localizationConstants.visitManagement,
			url: ROUTES.VISIT_MANAGEMENT,
			icon: <VisitIcon />,
			className: 'dsm'
		},
		[MODULE.BUSINESS_PLAN]: {
			label: localizationConstants.businessPlan,
			url: ROUTES.BUSINESS_PLAN,
			icon: <BusinessPlanIcon />,
			className: 'business-plan'
		}
	};
	return menuItems;
};

export default MenuItems;
