// DrawerContent.tsx
import React, { FC, Fragment, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Collapse, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import moment from 'moment';
// Functions/Constants/Enum
import { MenuItemPropsTypes } from 'hoc/layout/Layout.types';
import * as actionTypes from 'store/action-types';
import MMSCANTooltip from 'shared/components/MMSCANToolTip/MMSCANToolTip';
import { ALLOWED_PROSPECT_MODULES, DEFAULT_PARAMS, FS_ACTIVE_PATHS } from 'shared/constants/constant';
import { createAction } from 'shared/util/Utility';
import MenuItems from './MenuItems';
import { fsFilterInitialState } from 'features/financialStatement/store/financialStatement.reducer';
import { visitSummaryReportInitialState } from 'features/visitManagement/store/visitSummaryReport.reducer';
import { IModuleDetails } from 'features/auth/login/LoginScreen.type';
import { IState } from 'shared/interface/state';
import {
	ChildListItemButtonStyles,
	CollapseWrapperStyles,
	ListItemButtonStyles,
	ListItemIconStyles,
	ListItemTextStyles
} from 'hoc/layout/style/Layout.styles';
import { colors } from 'assets/styles/MMSCANTheme';

interface IDrawerContentProps {
	isOpen: boolean;
	modules: IModuleDetails[];
	pathname: string;
	setIsOpen: (flag: boolean) => void;
}

const DrawerContent: FC<IDrawerContentProps> = ({ isOpen, modules, pathname, setIsOpen }) => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const menuItems = MenuItems();
	const authInfo = useSelector((state: IState) => state.auth);

	const showModules = authInfo.is_prospect
		? Object.fromEntries(Object.entries(menuItems).filter(([key]) => ALLOWED_PROSPECT_MODULES.includes(key)))
		: menuItems;

	const { t } = useTranslation();

	const [openReports, setOpenReports] = useState(false);
	const [openCollapsibleReports, setOpenCollapsibleReports] = useState(false);
	const [menuListItem, setMenuListItem] = useState<MenuItemPropsTypes[]>([]);

	const pathNameMatcher = (path: string) =>
		pathname.startsWith(path) || (path === '/monthly-financial-statement' && FS_ACTIVE_PATHS.includes(pathname));
	// For FS need to allow all FS related paths

	const handleReportCollapse = () => {
		if (isOpen) {
			setOpenReports(!openReports);
		} else {
			setOpenCollapsibleReports(!openCollapsibleReports);
		}
	};

	const handleListItemClick = (url: string) => {
		if (isOpen || openReports || openCollapsibleReports) {
			setOpenReports(url.includes('/reports'));
			setIsOpen(false);
			setOpenCollapsibleReports(false);
		}
		navigate(url);

		// Define default parameters once
		const defaultParams = DEFAULT_PARAMS;
		const emptyFilter = {};
		const dataPayload = { pagination: defaultParams, filter: emptyFilter };
		const actionsWithDefaultParams = [
			actionTypes.USER_DATA,
			actionTypes.DEALER_DATA,
			actionTypes.VISIT_DATA,
			actionTypes.AGENDA_ACTION,
			actionTypes.ACTION_SCREEN,
			actionTypes.BUSINESS_PLAN
		];

		// Dispatch actions with default parameters
		actionsWithDefaultParams.forEach((action) => {
			dispatch(createAction(action, dataPayload));
		});
		dispatch(createAction(actionTypes.FS_FILTER, { ...fsFilterInitialState }));
		dispatch(createAction(actionTypes.CLOSED_DATA, { year: moment().year() }));
		dispatch(
			createAction(actionTypes.VISIT_SUMMARY_REPORTS_DATA, { ...visitSummaryReportInitialState, dealer_code: '' })
		);
	};

	useEffect(() => {
		const filteredMenuItems = modules
			?.map((module) => module.permissions.length > 0 && showModules[module.module_name])
			.filter(Boolean);
		filteredMenuItems && setMenuListItem([...(filteredMenuItems as MenuItemPropsTypes[])]);
	}, [modules]);

	const miniCollapseRef: React.RefObject<HTMLInputElement> = useRef(null);

	useEffect(() => {
		const handleOutsideClick = (event: Event) => {
			if (miniCollapseRef.current && !miniCollapseRef.current.contains(event.target as HTMLInputElement)) {
				// Clicked outside the sidebar, so close it
				setOpenCollapsibleReports(false);
			}
		};

		// Attach the event listener when the component mounts
		document.addEventListener('mousedown', handleOutsideClick);

		// Detach the event listener when the component unmounts
		return () => {
			document.removeEventListener('mousedown', handleOutsideClick);
		};
	}, []);

	return (
		<List sx={{ marginTop: '49px' }}>
			{menuListItem.map((item) => (
				<Fragment key={item.label}>
					<MMSCANTooltip title={t(item.label as string)} arrow placement='right'>
						<ListItem disablePadding sx={{ display: 'block' }}>
							<ListItemButton
								onClick={() =>
									item.children ? handleReportCollapse() : handleListItemClick(item.url as string)
								}
								selected={pathNameMatcher(item.url as string)}
								sx={{
									...ListItemButtonStyles,
									justifyContent: isOpen ? 'initial' : 'center',
									color: pathNameMatcher(item.url as string)
										? colors.primary.main
										: colors.white.main,
									borderLeft: pathNameMatcher(item.url as string)
										? '4px solid'
										: '4px solid transparent'
								}}
							>
								<ListItemIcon
									sx={{
										...ListItemIconStyles,
										mr: isOpen ? '15px' : '0px',
										color: pathNameMatcher(item.url as string)
											? colors.primary.main
											: colors.white.main
									}}
									className={
										pathNameMatcher(item.url as string)
											? `active-sidenav-icon--${item.className}`
											: ''
									}
								>
									{item.icon as React.JSX.Element}
								</ListItemIcon>
								{isOpen && (
									<ListItemText
										primary={
											<Typography
												variant='body2'
												sx={{
													...ListItemTextStyles,
													maxWidth: isOpen ? '170px' : '0px'
												}}
											>
												{t(item.label as string)}
											</Typography>
										}
										sx={{
											...ListItemTextStyles,
											opacity: isOpen ? 1 : 0
										}}
									/>
								)}
								{item.children && isOpen && (openReports ? <ExpandLess /> : <ExpandMore />)}
							</ListItemButton>
						</ListItem>
					</MMSCANTooltip>
					{item.children && (
						<Collapse
							in={openReports || openCollapsibleReports}
							timeout='auto'
							unmountOnExit
							ref={miniCollapseRef}
							className={!isOpen && openCollapsibleReports ? 'child-item-list-wrapper' : ''}
							sx={CollapseWrapperStyles(isOpen, openCollapsibleReports, menuListItem)}
						>
							<List component='div' disablePadding>
								{item.children.map((childItem, childItemIndex) => {
									const isSelected = pathNameMatcher(childItem.url as string);

									return (
										<ListItem
											key={childItemIndex}
											disablePadding
											sx={{ paddingLeft: !isOpen ? '' : '35px' }}
										>
											<ListItemButton
												id='listItemButton'
												onClick={() => handleListItemClick(childItem.url as string)}
												data-testid='listItemButton'
												selected={isSelected}
												sx={ChildListItemButtonStyles(isOpen, isSelected)}
											>
												<ListItemText
													primary={
														<Typography
															variant='body2'
															style={{
																maxWidth: '170px',
																lineHeight: 'normal'
															}}
														>
															{childItem.label}
														</Typography>
													}
													sx={{ wordBreak: 'break-word', maxWidth: '170px' }}
												/>
											</ListItemButton>
										</ListItem>
									);
								})}
							</List>
						</Collapse>
					)}
				</Fragment>
			))}
		</List>
	);
};

export default DrawerContent;
