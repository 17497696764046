import React, { useState, useEffect, useRef } from 'react';
import { Tooltip } from '@mui/material';
import { TooltipProps as MuiTooltipProps } from '@mui/material/Tooltip/Tooltip';
import { colors } from 'assets/styles/MMSCANTheme';

interface IProps {
	offset?: number[];
	maxWidth?: string;
}

const MMSCANTooltip = ({
	children,
	offset = [10, -5],
	maxWidth,
	...props
}: IProps & MuiTooltipProps): React.JSX.Element => {
	const [open, setOpen] = useState(false);
	const tooltipRef = useRef<HTMLDivElement | null>(null);

	useEffect(() => {
		const handleScroll = () => {
			if (open) setOpen(false);
		};

		window.addEventListener('scroll', handleScroll, { passive: true });

		return () => window.removeEventListener('scroll', handleScroll);
	}, [open]);

	return (
		<Tooltip
			ref={tooltipRef}
			data-testid='tooltip'
			PopperProps={{
				modifiers: [
					{
						name: 'offset',
						options: {
							offset
						}
					}
				]
			}}
			slotProps={{
				tooltip: {
					style: {
						backgroundColor: colors.grey.shade2,
						textTransform: 'capitalize',
						maxWidth: maxWidth || '300px' // Adjust maxWidth here
					}
				}
			}}
			open={open}
			onMouseEnter={() => setOpen(true)}
			onMouseLeave={() => setOpen(false)}
			{...props}
		>
			{children}
		</Tooltip>
	);
};

export default MMSCANTooltip;
