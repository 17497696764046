import { KeyboardEvent } from 'react';
import moment, { Moment } from 'moment';
import isEmpty from 'lodash/isEmpty';
import isNull from 'lodash/isNull';
import CryptoJS from 'crypto-js';

import AuthService from 'shared/services/Auth.service';
import { IAction } from 'shared/interface/state';
import { IDropDownOption } from 'shared/interface';
import { NOT_NUMBER_REGEX } from 'shared/constants/constant';

const KEY: string = process.env['REACT_APP_ENCRYPTION_KEY'] as string;

/**
 * create action creator
 * @param ACTION - type of action
 * @param data - data
 */
export const createAction = (ACTION: string, data: any = null): IAction => ({
	type: ACTION,
	payload: data
});

export const checkPermission = (module: string) => {
	const userData = AuthService.getAuthData();
	const currentModule =
		(userData &&
			userData.modules?.filter(
				({ module_name, permissions }) => permissions.length > 0 && module_name === module
			)) ||
		[];
	// Check if the user has the required permission
	const hasRequiredPermission = !isEmpty(currentModule); /*Remove true before PR*/
	return hasRequiredPermission;
};

export const checkTimeline = () => {
	const userData = AuthService.getAuthData();
	const isTimelineStarted = moment().isAfter(moment(userData?.sales_start_date));
	const isTimelineEnded = moment().isAfter(moment(userData?.sales_end_date));
	const hasBetweenTimeline = isTimelineStarted && !isTimelineEnded;
	return hasBetweenTimeline;
};

export const dateFormate = (date: Date | string | Moment | null, format = 'YYYY-MM-DD') => {
	if (!date) return '';
	const language = localStorage.getItem('lang') || 'en';
	moment.locale(language); // Set the locale

	// Check if the format contains time-related tokens like 'HH' or 'mm'
	const hasTime = format.includes('HH') || format.includes('mm');

	// If time is included, convert to local time, otherwise keep the date in UTC
	return hasTime
		? moment.utc(date).local().format(format) // Convert to local time for time formats
		: moment.utc(date).format(format); // Keep UTC date if no time format
};

export const calculateDayDifference = (date: string, specificDate: string) => {
	// Convert both input parameters to moment objects if they are not already
	const startDate: Moment = moment(date);
	const endDate: Moment = moment(specificDate);

	// Calculate the day difference
	const dayDifference: number = endDate.diff(startDate, 'days') + 1;

	return `${dayDifference} ${dayDifference === 1 ? 'Day' : 'Days'}` as string;
};

// Matches any alphabets after the number except 'X' or 'x' for Total Gross & Total sales
export const suffixRegex = /\d+([A-Wa-wY-Zy-z]+)$/;

//Remove character from account number
export const cleanedAccountNumber = (accNo: string) => accNo.replace(/[A-Za-z]/g, '');

//For set details according to order number
export const sortableDetails = (details: any) =>
	details && details.sort((a: any, b: any) => a.order_number - b.order_number);

export const formatCanadianCurrency = (amount: number, locale = 'en-CA', sign = ''): string => {
	if (amount === undefined) {
		return '';
	} else {
		let value = new Intl.NumberFormat(locale, {
			currency: 'CAD'
		}).format(amount);

		if (!isNull(sign) && !isNull(amount)) {
			if (sign === '$') {
				value = `$${value}`;
			} else if (sign === '%') {
				value = `${amount}%`;
			}
		}
		return value;
	}
};

export const handleKeyDown = (e: any, fieldName = '') => {
	if ((fieldName === 'phone' || fieldName === 'fax') && e.key.match(NOT_NUMBER_REGEX)) {
		e.preventDefault();
		return;
	}
	if (e.key === ' ' && (e.target.selectionStart === 0 || e.target.selectionStart === null)) {
		e.preventDefault();
	}
};

export const getFirstLetter = (name: string, char?: number) => {
	const initials = name
		.split(' ')
		.map((part) => part.charAt(0))
		.join('')
		.slice(0, char || 2);
	return initials;
};

export const decryptPowerBIUrl = (encryptedUrl: string) => {
	const [ivHex, encrypted] = encryptedUrl.split(':');

	const iv = CryptoJS.enc.Hex.parse(ivHex as string);
	const decrypted = CryptoJS.AES.decrypt(
		{
			ciphertext: CryptoJS.enc.Hex.parse(encrypted as string)
		} as any,
		CryptoJS.enc.Hex.parse(KEY),
		{
			iv: iv,
			mode: CryptoJS.mode.CBC,
			padding: CryptoJS.pad.Pkcs7
		}
	);

	return decrypted.toString(CryptoJS.enc.Utf8);
};

export const debounce = (func: any, wait = 200) => {
	// eslint-disable-next-line no-undef
	let h: NodeJS.Timeout;
	return (...args: any) => {
		clearTimeout(h);
		h = setTimeout(() => func(...args), wait);
	};
};

export const dateTimeFormat = (date: string, time: string) => {
	const formattedDate = moment(date).isValid() ? moment(date).format('YYYY-MM-DD') : '';
	const formattedTime = moment(time).format('HH:mm');

	// Return both formatted date and time in the required format
	return `${formattedDate} ${formattedTime}`.trim(); // Trim in case one of them is empty
};

export const downloadFile = async (event: any, url: string, fileName: string) => {
	event.preventDefault();
	try {
		const response = await fetch(url);
		if (!response.ok) {
			throw new Error(`HTTP error! status: ${response.status}`);
		}
		const blob = await response.blob();
		const urlBlob = window.URL.createObjectURL(blob);
		const downloadLink = document.createElement('a');
		downloadLink.href = urlBlob;
		downloadLink.download = fileName;
		document.body.appendChild(downloadLink);
		downloadLink.click();
		document.body.removeChild(downloadLink);
		window.URL.revokeObjectURL(urlBlob);
	} catch (error) {
		console.error(error);
	}
};

// Checks if the input is a negative numeric value after removing commas
export const isNegativeNumericValue = (detail: string | number) => {
	const numericValue = parseFloat(String(detail).replace(/,/g, ''));
	return numericValue < 0;
};

// Function to generate 12 month ranges
export const generateMonthlyRanges = (endYear: number, endMonth: number): IDropDownOption[] => {
	const ranges: IDropDownOption[] = [];
	const startDate = new Date(endYear - 1, endMonth); // Start month is 0-indexed, so endMonth should be adjusted if using 1-indexing

	// Generate 12 ranges, starting from the month before the end month
	for (let i = 0; i < 12; i++) {
		// Calculate the start date for each range
		const start = new Date(startDate.getFullYear(), startDate.getMonth() - i, 1);

		// Calculate the end date which is exactly 12 months after the start date
		const end = new Date(start.getFullYear(), start.getMonth() + 12, 0);

		// Format the range as "Month Year to Month Year"
		const startRange = `${start.toLocaleString('default', { month: 'long' })} ${start.getFullYear()}`;
		const endRange = `${end.toLocaleString('default', { month: 'long' })} ${end.getFullYear()}`;
		const range = `${startRange} to ${endRange}`;

		// Push the formatted range into the list
		ranges.push({ label: range, value: range });
	}

	// Return the ranges sorted by the starting month of each range
	return ranges.sort(
		(a, b) =>
			new Date(a.label.split(' to ')[0] ?? '').getTime() - new Date(b.label.split(' to ')[0] ?? '').getTime()
	);
};

export const getRandomColor = () => {
	// Generate colors with high saturation and medium lightness
	const hue = Math.floor(Math.random() * 360); // Hue range from 0 to 360
	const saturation = 60 + Math.random() * 20; // Saturation between 60% to 80%
	const lightness = 50 + Math.random() * 10; // Lightness between 50% to 60%

	// Convert HSL to CSS format
	const color = `hsl(${hue}, ${saturation}%, ${lightness}%)`;

	return color;
};

export const convertToShortMonth = (dateStr: string, format = 'monthYear'): string => {
	// Parse the input date string assuming it is in 'MMMM YYYY' format
	const date = moment(dateStr, 'MMMM YYYY');
	if (!date.isValid()) {
		throw new Error('Invalid date format');
	}

	// Format based on the provided format option
	switch (format) {
		case 'month':
			return date.format('MMM'); // Only short month,
		case 'MM':
			return date.format('MM'); // Only short month,
		case 'year':
			return date.format('YYYY'); // Only short month
		case 'monthYear':
		default:
			return date.format('MMM YYYY'); // Short month with year
	}
};

//Allow only digits
export const handleKeyDownForNumbers = (e: KeyboardEvent<HTMLInputElement>) => {
	const allowedKeys = ['Backspace', 'Tab', 'ArrowLeft', 'ArrowRight', 'Delete']; // Allow control keys

	// Check if the pressed key is not a digit (0-9) and not a control key
	if (!/[0-9]/.test(e.key) && !allowedKeys.includes(e.key)) {
		e.preventDefault(); // Prevent any non-numeric input
	}
};
