import { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { HelpRounded as HelpIcon, Notifications as NotificationsIcon } from '@mui/icons-material';
import { Badge, BadgeProps, Box, IconButton, styled } from '@mui/material';
import isEmpty from 'lodash/isEmpty';

import { IModuleDetails, IRole } from 'features/auth/login/LoginScreen.type';
import { Drawer, DrawerBoxStyles, DrawerHeader, StyledIconButton, StyledSwitch } from 'hoc/layout/style/Layout.styles';
import { refreshLanguage } from 'shared/util/localization';
import localizationConstants from 'shared/util/translation/constant';
import { MODULE_ROUTES, ROUTES } from 'shared/constants/constant';
import { IState } from 'shared/interface/state';
import MMSCANTooltip from '../MMSCANToolTip/MMSCANToolTip';
import DrawerContent from './components/DrawerContent';
import HelpDrawer from './components/lookup';
import NotificationDrawer from './components/NotificationDrawer';
import ProfileMenu from './components/ProfileMenu';
import { colors } from 'assets/styles/MMSCANTheme';
import * as HeaderSx from './style/LayoutHeader.style';
import { MenuIcon, MenuOpenIcon, MMSCANLogo } from 'assets/images/icon';

const StyledBadge = styled(Badge)<BadgeProps>(() => ({
	color: colors.primary.main,
	'& .MuiBadge-badge': {
		right: 0,
		top: 0,
		backgroundColor: colors.grey.shade3,
		padding: '0 4px'
	}
}));

interface ISideNavProps {
	isSideNavOpen: boolean;
	setIsSideNavOpen: (flag: boolean) => void;
}

const SideNav: FC<ISideNavProps> = ({ isSideNavOpen, setIsSideNavOpen }) => {
	const { t } = useTranslation();
	const { pathname } = useLocation();
	const navigate = useNavigate();
	const authData = useSelector((state: IState) => state.auth);
	const role = authData.role as IRole;
	const modules = authData.modules as IModuleDetails[];
	const firstName = authData?.first_name ?? '';
	const lastName = authData?.last_name ?? '';
	const isFrenchLang = localStorage.getItem('lang') === 'fr';

	const [updateNavigation, setUpdateNavigation] = useState('');
	const [isTooltipOpen, setIsTooltipOpen] = useState<boolean>(false);
	const [accessModules, setAccessModules] = useState(modules);
	const [isOpenDrawer, setIsOpenDrawer] = useState<boolean>(false);
	const [isOpenHelpDrawer, setIsOpenHelpDrawer] = useState<boolean>(false);
	const [unreadCount, setUnreadCount] = useState<number>(0);
	const [checked, setChecked] = useState(isFrenchLang);

	// Update localStorage and state when the switch is toggled
	const handleChangeLanguage = useCallback(
		(event: { target: { checked: boolean } }) => {
			const newLang = event.target.checked ? 'fr' : 'en';

			setChecked(event.target.checked);
			localStorage.setItem('lang', newLang);
			refreshLanguage(newLang);

			authData.isLoggedIn && pathname !== ROUTES.LOGIN && window.location.reload();
		},
		[authData.isLoggedIn, pathname]
	);

	const handleUnreadCount = (count: number) => setUnreadCount(count);

	useEffect(() => {
		if (modules) {
			// Filter modules to get those with non-empty permissions
			const filteredModules = modules.find((module) => !isEmpty(module.permissions));
			// Set the navigation to the route corresponding to the first found module
			setAccessModules(modules.filter((module) => module));
			filteredModules && setUpdateNavigation(MODULE_ROUTES[filteredModules.module_name]);
		}
	}, [authData.user_id, modules]);

	useEffect(() => {
		const handleKeyDown = (event: KeyboardEvent) => {
			if (event.key === 'Escape') {
				setIsTooltipOpen(false);
			}
		};

		document.addEventListener('keydown', handleKeyDown);

		return () => {
			document.removeEventListener('keydown', handleKeyDown);
		};
	}, []);

	useEffect(() => {
		// Sync state with localStorage on mount in case localStorage was updated externally
		const storedLang = localStorage.getItem('lang');
		setChecked(storedLang === 'fr');
	}, []);

	const isNotificationPermission = useCallback(
		(modules: { module_name: string; permissions: string[] }[]): boolean =>
			modules?.some((module) => module.module_name === 'notification' && module.permissions.length > 0),
		[]
	);

	const renderIconButton = (id: string, isOpen: boolean, icon: any) => (
		<StyledIconButton onClick={() => setIsSideNavOpen(isOpen)} id={id} data-testid={id} open={isSideNavOpen}>
			{icon}
		</StyledIconButton>
	);
	return (
		<>
			<Drawer
				variant='permanent'
				open={isSideNavOpen}
				sx={{
					'.MuiPaper-root': {
						background: colors.black.main
					}
				}}
			>
				<Box>
					<DrawerHeader open={isSideNavOpen}>
						<Box sx={HeaderSx.logoWrapper}>
							<Box sx={DrawerBoxStyles()} onClick={() => navigate(updateNavigation)}>
								<MMSCANLogo height='100%' width='100%' />
							</Box>
						</Box>
						{renderIconButton(
							isSideNavOpen ? 'openDrawer' : 'closeDrawer',
							!isSideNavOpen,
							isSideNavOpen ? <MenuOpenIcon /> : <MenuIcon />
						)}
					</DrawerHeader>
					<DrawerContent
						isOpen={isSideNavOpen}
						setIsOpen={setIsSideNavOpen}
						pathname={pathname}
						modules={accessModules}
					/>
				</Box>
				<Box>
					<Box
						sx={{
							...HeaderSx.infoRoot,
							margin: isSideNavOpen ? '2px 10px' : '2px 7px',
							justifyContent: isSideNavOpen ? 'start' : 'center'
						}}
						data-testid='languageInfo'
					>
						<StyledSwitch
							checked={checked}
							onChange={handleChangeLanguage}
							inputProps={{ 'aria-label': 'language toggle' }}
						/>
					</Box>
					{!authData.is_prospect && (
						<Box
							sx={{
								...HeaderSx.infoRoot,
								margin: isSideNavOpen ? '2px 15px' : '2px 7px',
								justifyContent: isSideNavOpen ? 'start' : 'center'
							}}
							data-testid='lookup'
						>
							<MMSCANTooltip title={t(localizationConstants.lookup)} arrow offset={[0, -15]}>
								<IconButton onClick={() => setIsOpenHelpDrawer(true)} data-testid='lookup'>
									<StyledBadge>
										<HelpIcon
											sx={{
												fontSize: 'medium',
												marginLeft: '3px',
												height: '30px',
												width: '30px'
											}}
										/>
									</StyledBadge>
								</IconButton>
							</MMSCANTooltip>
						</Box>
					)}
					{isNotificationPermission(modules) && (
						<Box
							sx={{
								...HeaderSx.infoRoot,
								margin: isSideNavOpen ? '0 15px 2px' : '0 7px 2px',
								justifyContent: isSideNavOpen ? 'start' : 'center'
							}}
							data-testid='notification'
						>
							<MMSCANTooltip
								title={t(localizationConstants.notifications)}
								arrow
								open={isTooltipOpen}
								onMouseEnter={() => setIsTooltipOpen(true)}
								onMouseLeave={() => setIsTooltipOpen(false)}
							>
								<IconButton onClick={() => setIsOpenDrawer(true)} data-testid='notificationButton'>
									<StyledBadge badgeContent={unreadCount > 0 ? unreadCount : 0} max={99}>
										<NotificationsIcon fontSize='medium' sx={{ height: '30px', width: '30px' }} />
									</StyledBadge>
								</IconButton>
							</MMSCANTooltip>
						</Box>
					)}
					<ProfileMenu
						isOpen={isSideNavOpen}
						firstName={firstName}
						lastName={lastName}
						roleColor={role?.role_color || ''}
					/>
				</Box>
			</Drawer>
			{(isNotificationPermission(modules) || !authData.is_prospect) && (
				<NotificationDrawer
					isOpen={isOpenDrawer}
					handleNotificationToggle={() => setIsOpenDrawer(!isOpenDrawer)}
					handleUnreadCount={handleUnreadCount}
					unreadCount={unreadCount}
				/>
			)}
			{isOpenHelpDrawer && (
				<HelpDrawer
					isOpen={isOpenHelpDrawer}
					handleNotificationToggle={() => setIsOpenHelpDrawer(!isOpenHelpDrawer)}
				/>
			)}
		</>
	);
};

export default SideNav;
