import moment from 'moment';
import * as actionTypes from 'store/action-types';
import { IAction } from 'shared/interface/state';
import { IFSFilterParams } from 'shared/interface';

// Calculate initial month and year
const currentYear = moment().year();
const currentMonth = moment().month() + 1; // moment months are 0-based
const isJanuary = currentMonth === 1;

export const fsFilterInitialState: IFSFilterParams = {
	year: isJanuary ? currentYear - 1 : currentYear, // If January, set to previous year
	month: isJanuary ? 12 : currentMonth - 1, // If January, set to December
	region: null,
	dealer_code: null
};

const financialReducer = (state = fsFilterInitialState, action: IAction): IFSFilterParams => {
	switch (action.type) {
		case actionTypes.FS_FILTER:
			return {
				...state,
				...action.payload
			};

		default:
			return state;
	}
};
export default financialReducer;
